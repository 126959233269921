import React from 'react'

const Background = () => {
  return (
    <>
      <div className="container-fluid px-0">
      <div className="banner py-5 text-center">
          <h2 className='heading-border w-25'>Our Background</h2>
        </div>
      </div>
    </>
  )
}

export default Background
