import React from 'react'

const About = () => {
  return (
    <>
      <div className="container-fluid px-0">
        <div className="banner py-5 text-center">
          <h2 className='heading-border'>About us</h2>
        </div>
      </div>
    </>
  )
}

export default About
