const articles=[
    {
        id:1,
        image:"/Assets/images/banner-second.jpg",
        title:"How India's Workers And Employment Policy Fared In 2021",
        shortDiscription:"Rules based on India's new labour welfare codes are yet to be finalised and implemented, the rural jobs programme remains underfunded despite high demand, and heading into 2022, India is yet to frame a comprehensive National Employment Policy",
        discription:"Bengaluru: As we head into 2022, India still lacks good quality data on migrant workers, rules for new labour codes that were passed in Parliament almost a year ago are yet to be finalised, budgetary provisions for the rural jobs programme have fallen short of demand for work, and the government told Parliament that no committee has yet been formed to draft a National Employment Policy (NEP). The unemployment rate in urban areas, which had seen a large exodus of migrant workers after the nationwide lockdown was imposed in March 2020, had decreased to pre-lockdown levels, at 9.4% in the first quarter of 2021, according to data from the Periodic Labour Force Survey. India's economy is also seeing a recovery, with the gross domestic product growing at 8.4% in the second quarter of 2021-22 compared to 7.4% during the same period last year.",
        date:"2024-6-28"
    },
    // {
    //     id:2,
    //     image:"/Assets/images/banner-second.jpg",
    //     title:"Employment Growth and Industrial Policy: The Challenge for Indian States",
    //     shortDiscription:"The nature of and the future potential for economic growth will vary across Indian States because of their differences in the rates of demographic transitions. The growing population of the young in some of the States in the east and north of the country, notably Bihar and Uttar Pradesh, opens up a huge economic opportunity.",
    //     discription:"The nature of and the future potential for economic growth will vary across Indian States because of their differences in the rates of demographic transitions. The growing population of the young in some of the States in the east and north of the country, notably Bihar and Uttar Pradesh, opens up a huge economic opportunity. It is also a serious policy challenge—to create new opportunities that meet the rising expectations of the job aspirants. At the same time, for States such as Kerala and Tamil Nadu, which have an ageing population, there are limits to future growth based on labour-intensive sectors. Across Indian States, during the period between 2005 and 2018, labour absorption into industry, construction and services lagged way behind the increase in the potential supply of workers into these sectors. The mismatch between labour demand and potential labour supply widened after 2012, leading to an increase in unemployment rates and a large-scale withdrawal of women from the labour market. For reviving employment growth, India requires a mix of social, employment and industrial policies. The States should have greater financial and functional autonomy to implement these policies in a way that suit their specific stages of development.",
    //     date:"2024-6-29"
    // },
]
export default articles