import React from 'react'
import MediaPhotoGallery from '../../Components/MediaPhotoGallery/MediaPhotoGallery'

const Media = () => {
  return (
    <>
        <div className="banner py-5 text-center">
          <h2 className='heading-border'>Media</h2>
        </div>
        <MediaPhotoGallery/>
    </>
  )
}

export default Media
