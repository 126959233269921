import React from 'react'
import BackgroundSlider from '../../Components/BackgroundSlider/BackgroundSlider'
import EventProgrames from '../../Components/EventProgrames/EventProgrames'

const Home = () => {
  return (
    <>
    <BackgroundSlider/>
    <EventProgrames/>
    </>
  )
}

export default Home
