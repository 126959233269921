import React from 'react'
import { Tweet } from 'react-twitter-widgets'
import { Link } from 'react-router-dom'

const MemorandumToLeaders = () => {
    return (
        <>
            <div className="container-fluid px-0">
                <div className="banner py-5 text-center">
                    <h2 className='heading-border w-50 w-sm-100'>Dear Leaders of India in the Parliament</h2>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <p className="py-3 font-15">Dear Leaders, People say politicians love <Link to="https://x.com/hashtag/poverty?src=hashtag_click" target="_blank">#poverty</Link> and <Link to="https://x.com/hashtag/unemployment?src=hashtag_click" target='_blank'>#unemployment</Link>. Can you prove them wrong? Starting from today, you will be meeting till August 12 for the 1st budget session of the 18th Lok Sabha
                        <Link to="https://x.com/LokSabhaSectt" target='_blank'>@LokSabhaSectt</Link>
                        . Can you discuss, decide and declare a time-bound result-oriented national <Link to="https://x.com/hashtag/EmploymentPolicy?src=hashtag_click" target='_blank'>#EmploymentPolicy</Link>? Your 21.59 cr youth (18-29 age group) voters of the country are watching you. 
                    </p>
                    <span className="text-end py-2" style={{paddingRight:"65px"}}>
                        राजनीति नहीं, रोजगार नीति <br/> <br/>
                        With warm regards,
                        <br />
                        <br />
                        <span style={{fontWeight:"bold"}}>Chandra Mishra</span><br />
                        One among the 29 cr parents of India
                        </span>
                    <p className="py-2" style={{fontWeight:"bold"}}>It's a social campaign posted on Twitter daily 9 AM throughout this Monsoon Session of the Lok Sabha. As a responsible People's Representative, you may track the tweets, share, quote, respond to and update the action taken by you, so that the citizens, especially the youth, can know how much you care for their future : <Link to="https://x.com/employonomics" target='_blank'>https://x.com/employonomics</Link></p>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1822835498785591503' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1822487146923458951' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1822117326851903769' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1821728680747426035' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1821390027290460301' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1821028560380809725' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1820676359250665569' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1820303761538949295' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1819942033299038511' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1819578627526148341' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1819219152650809396' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1818855243368538175' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1818496853266072038' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1818129152462971353' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1817753180827975822' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1817405485202468954' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1817029316917526600' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1816651877414588490' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1816319039041314844' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1815954335987105972' />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 p-2">
                        <div className="box-shadow p-3">
                            <div className="twitter" style={{ height: "430px", overflowY: "scroll" }}>
                                <Tweet tweetId='1815597577192067204' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MemorandumToLeaders
